/* eslint-disable max-statements */
import React, { createRef } from 'react'
import style from './tablet.module.css'
import Description from '../description/description'
import { loadedImg, dynamicRef, getStyleItemByProperty } from '../../../Utils/utils'

/**
 * Desktop carrousel component.
 * @returns {void} .
 */
class Tablet extends React.Component {
  /**
   * Desktop carrousel component.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.curLeft = 0
    this.moveX = 0
    this.startX = 0
    this.curSlide = 0
    this.loadedCnt = 0
    this.slideW = 0
    this.offsetLeft = 0
    this.slideMargin = 0
    this.cantSliderEnable = 2
    this.totalSlides = props.info.length

    this.refSlider = createRef()
    this.refSliderTable = createRef()
    this.refImage = createRef()
    this.refAllSlide = dynamicRef(this.totalSlides)
    this.refDots = createRef()
    this.refWrapper = createRef()

    this.def = {
      transition: {
        speed: 300,
        easing: ''
      },
      swipe: true,
      autoHeight: false,
      afterChangeSlide: () => { }
    }
  }

  /**
   * function call after unmount.
   * @returns {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.updateSliderDimension)
    this.init()
    this.updateSliderDimension()
  }

  /**
   * function call before unmount.
   * @returns {void}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSliderDimension)
  }

    init = () => {
      this.initialMove = 0
      const allSlide = this.refAllSlide

      for (const item of allSlide) {
        loadedImg(item.current, this.updateSliderDimension, this.totalSlides)
      }
      this.setDot()
      this.getSlideW()
    }

    setDot = () => {
      const before = 1
      if (this.totalSlides <= this.cantSliderEnable) return
      const { children } = this.refDots.current

      for (let index = this.initialMove; index < children.length; index++) {
        if (index === this.curSlide || index - before === this.curSlide) {
          children[index].classList.add(style.SliderActive)
          children[index].classList.remove(style.SliderBotton)
        } else {
          children[index].classList.add(style.SliderBotton)
          children[index].classList.remove(style.SliderActive)
        }
      }
      if (this.curSlide === this.totalSlides - before) {
        children[this.curSlide - before].classList.add(style.SliderActive)
        children[this.curSlide - before].classList.remove(style.SliderBotton)
      }
    }

    updateSliderDimension = () => {
      const widtOut = 90
      if (this.refSlider && this.refSlider.current && this.refSlider.current.style) {
        this.refSlider.current.style.width = `${((window.innerWidth - widtOut) / this.cantSliderEnable) * this.totalSlides}px`
      }

      this.refWrapper.current.style.width = `${window.innerWidth - widtOut}px`

      this.getSlideW()
    }

    getSlideW = () => {
      const allSlider = this.refAllSlide
      if (allSlider.length > this.initialMove && allSlider[this.initialMove]
          && allSlider[this.initialMove].current) {
        const node = allSlider[0].current
        this.slideW = parseInt(node.offsetWidth, 10)
        this.offsetLeft = parseInt(node.offsetLeft, 10)
        this.slideMargin = getStyleItemByProperty(node, 'margin-left')
      } else this.slideW = 0
    }

    getCurrentLeft = () => {
      const { left } = this.refSlider.current.style
      if (left) this.curLeft = parseInt(left, 10)
    }

    /**
     * function to go next slider.
     * @param {boolean} changing update slider.
     * @param {int} pos next slider position.
     * @returns {void}
     */
    gotoSlide = (changing, pos) => {
      const milisecond = 1000
      const mov = 1
      const pxShadow = 10
      if (changing) {
        this.curSlide = pos
      }
      this.refSlider.current.style.transition = `left ${this.def.transition.speed / milisecond}s ${this.def.transition.easing}`
      if (this.curSlide === this.totalSlides - mov) {
        this.refSlider.current.style.left = `${-(this.curSlide - mov) * (this.slideW + this.slideMargin + pxShadow)}px`
      } else {
        this.refSlider.current.style.left = `${-this.curSlide * (this.slideW + this.slideMargin + pxShadow)}px`
      }

      setTimeout(() => {
        this.refSlider.current.style.transition = ''
      }, this.def.transition.speed)

      this.setDot()
    }

    /**
     * Event start touching.
     * @param {object} event .
     * @returns {void} .
     */
    startMove = (event) => {
      this.getCurrentLeft()
      const touch = event.targetTouches[0] || event.changedTouches[0]
      this.startX = touch.pageX
    }

    /**
     * Event touching.
     * @param {object} event .
     * @returns {void} .
     */
    Moving = (event) => {
      const cantStop = 40
      const touch = event.targetTouches[0] || event.changedTouches[0]
      this.moveX = touch.pageX

      if (Math.abs(this.moveX - this.startX) < cantStop) return

      this.refSlider.current.style.left = `${this.curLeft + this.moveX - this.startX}px`
    }

    endMove = () => {
      const cantStop = 40
      const mov = 0
      this.getCurrentLeft()

      if (Math.abs(this.moveX - this.startX) === this.initialMove
        || (this.moveX === this.initialMove)) return

      const stayAtCur = !!(Math.abs(this.moveX - this.startX) < cantStop
        || this.moveX === this.initialMove)
      const dir = this.startX < this.moveX ? 'left' : 'right'

      if (!stayAtCur) {
        dir === 'left' ? this.curSlide -= 1 : this.curSlide += 1
        if (this.curSlide < this.initialMove) {
          this.curSlide = 0
        } else if (this.curSlide === this.totalSlides - mov
            || (this.curSlide === this.totalSlides)) {
          this.curSlide -= 1
        }
      }
      this.gotoSlide(false)
      this.restValues()
    }

    restValues = () => {
      this.startX = 0
      this.moveX = 0
    }

    /**
     * Render Component
     * @returns {jsx} .
     */
    render() {
      const { info } = this.props
      return (
        <div className={style.TabletContainer}>
          <div className={style.CarouselTablet}>
            <div
              ref={this.refWrapper}
              className={style.Wrapper}
            >
              <div
                ref={this.refSlider}
                onTouchStart={(event) => this.startMove(event)}
                onTouchMove={(event) => this.Moving(event)}
                onTouchEnd={(event) => this.endMove(event)}
                className={style.SlideshowContainerTable}
              >
                {info.map((item, index) => {
                  const image = window.webpSupported ? item.webpBig : item.jpgBig
                  return (
                    <div
                      ref={this.refAllSlide[index]}
                      key={item.id}
                      className={style.PhotoTablet}
                    >
                      <img
                        alt="step-one"
                        className={style.CarouselImage}
                        data-src={image}
                      />
                      <Description
                        item={item}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {this.totalSlides > this.cantSliderEnable && (
            <div
              ref={this.refDots}
              className={style.SliderBootOut}
            >
              {info.map((item, index) => (
                <button
                  type="button"
                  key={item.id}
                  onClick={() => { this.gotoSlide(true, index) }}
                />
              ))}
            </div>
          )}
        </div>
      )
    }
}
export default Tablet
