import React from 'react'
import style from './index.module.css'
import Desktop from './desktop/desktop'
import Mobile from './mobile/mobile'
import Tablet from './tablet/tablet'

/**
 * Carrousel component.
 * @returns {void} .
 */
const StepsCarousel = ({ info, newUser }) => (
  <div className={style.CarouselContainer}>
    <div className={style.ContainerTitle}>
      <div className={style.SectionTitle}>{newUser ? info.title : info.tiltle2}</div>
      <div className={style.SectionSubTitle}>{info.subtitle}</div>
    </div>
    <Mobile info={info.data} />
    <Tablet info={info.data} />
    <Desktop info={info.data} />
  </div>
)


export default StepsCarousel
