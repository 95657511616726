import React from 'react'
import style from './desktop.module.css'
import appStyles from '../../../App.module.css'
import Description from '../description/description'
import { loadedImg, dynamicRef } from '../../../Utils/utils'

/**
 * Desktop carrousel component.
 * @returns {void} .
 */
class Desktop extends React.Component {
  /**
   * Constructor Desktop carrousel.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.totalSlides = props.info.length
    this.refSlides = dynamicRef(this.totalSlides)
  }

  /**
   * function call after unmount.
   * @returns {void}
   */
  componentDidMount() {
    this.init()
  }

  init = () => {
    const allSlide = this.refSlides
    for (const item of allSlide) {
      loadedImg(item.current, () => { }, this.totalSlides, false)
    }
  }

  /**
   * Render Component
   * @returns {jsx} .
   */
  render() {
    const { info } = this.props
    return (
      <div className={[style.DesktopContainer, appStyles.ContentWrapper].join(' ')}>
        <div className={style.CarouselDesktop}>
          <div className={style.SlideShowContainerDesktop}>
            {info.map((item, index) => {
              const image = window.webpSupported ? item.webpBig : item.jpgBig
              return (
                <div
                  ref={this.refSlides[index]}
                  key={item.id}
                  className={style.PhotoDesktop}
                >
                  <img
                    data-src={image}
                    alt={index}
                    className={style.CarouselImage}
                  />
                  <Description item={item} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Desktop
