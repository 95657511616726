import React from 'react'
import moon from '../../assets/images/png/lunafooter.png'
import ring from '../../assets/images/png/path.png'
import styles from './LoaderFullScreen.module.css'


/**
 * Loader Full Screen.
 * @returns {void} .
 */
const LoaderFullScreen = ({ message }) => (
  <div className={styles.Container}>
    <div className={styles.SealRing}>
      <img
        src={ring}
        alt="Animacion"
      />
      <div className={styles.SealMoon}>
        <img
          src={moon}
          alt="Holograma Moons"
        />
      </div>
    </div>
    <div className={styles.ContainerMessage}>{message}</div>
  </div>
)

export default LoaderFullScreen
