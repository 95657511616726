/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createRef } from 'react'
import styles from './Form.module.css'
import messages from './Form.messages'

/**
 * Form component.
 * @returns {void} .
 */
export default class Form extends Component {
  /**
   * Desktop carrousel component.
   * @param {object} props .
   * @returns {void} .
   */
  constructor() {
    super()
    this.state = {
      phoneError: '',
      emailError: '',
      nameError: ''
    }

    this.refName = createRef()
    this.refPhone = createRef()
    this.refEmail = createRef()
  }

  handleNext = () => {
    const name = this.refName.current.value
    const phone = this.refPhone.current.value
    const email = this.refEmail.current.value

    const nameError = !this.validateName(name)
    const emailError = !this.validateEmail(email)
    const phoneError = !this.validatePhone(phone)

    this.setState({ nameError, emailError, phoneError })
    if (phoneError === false && emailError === false && nameError === false) {
      this.props.submit({ name, phone, email })
    }
  };

  /**
   * Validate name.
   * @param {string} name .
   * @returns {void} .
   */
  validateName = (name) => {
    const first = 0
    const second = 1
    if (name.charAt(first) !== ' ') {
      const nameArray = name.split(' ')
      return nameArray.length > second
    }
    return false
  }

  /**
   * Validate email.
   * @param {string} email .
   * @returns {void} .
   */
  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(String(email).toLowerCase())
  }

  /**
   * Validate phone.
   * @param {string} phone .
   * @returns {void} .
   */
  validatePhone = (phone) => {
    const re = /^\d{10}$/
    return phone.match(re)
  }

  /**
   * Render Component
   * @returns {jsx} .
   */
  render() {
    const {
      name,
      email,
      phone,
      title,
      titleStyle,
      button
    } = this.props

    const { nameError, emailError, phoneError } = this.state

    const myTitleStyle = titleStyle === 'CooperBT'
      ? styles.TitleCooperBT
      : styles.TitleModernEra
    const buttonText = button || 'SIGUIENTE'

    return (
      <div className={[styles.ContainerFlex, styles.HeightContainer].join(' ')}>
        <div className={myTitleStyle}>{title}</div>
        <div className={styles.Container}>
          <div className={styles.Form}>
            <label
              htmlFor="name"
              className={styles.Inp}
            >
              <input
                id="name"
                ref={this.refName}
                type="text"
                placeholder="&nbsp;"
                defaultValue={name}
              />
              <span className={styles.Label}>{messages.name}</span>
              <span className={styles.Border} />
            </label>
          </div>
          {nameError && (
            <label className={styles.Error}>{messages.nameError}</label>
          )}
          <div className={styles.Form}>
            <label
              htmlFor="phone"
              className={styles.Inp}
            >
              <input
                id="phone"
                ref={this.refPhone}
                maxLength="10"
                defaultValue={phone}
                placeholder="&nbsp;"
              />
              <span className={styles.Label}>{messages.phone}</span>
              <span className={styles.Border} />
            </label>
          </div>
          {phoneError && (
            <label className={styles.Error}>{messages.phoneError}</label>
          )}
          <div className={styles.Form}>
            <label
              htmlFor="email"
              className={styles.Inp}
            >
              <input
                id="email"
                ref={this.refEmail}
                type="email"
                defaultValue={email}
                placeholder="&nbsp;"
              />
              <span className={styles.Label}>{messages.email}</span>
              <span className={styles.Border} />
            </label>
          </div>
          {emailError && (
            <label className={styles.Error}>{messages.emailError}</label>
          )}
        </div>

        <div className={styles.NextContainer}>
          <div
            role="button"
            tabIndex="0"
            id="next"
            className={button ? `${styles.ButtonNext} ${styles.ButtonNextCite}` : styles.ButtonNext}
            onClick={this.handleNext}
          >
            {buttonText}
          </div>
        </div>
      </div>
    )
  }
}
