/* eslint-disable global-require */
import React, { useRef, useEffect } from 'react'
import styles from './Hero.module.css'
import appStyles from '../../App.module.css'

/**
 * choose Image.
 * @param {object} srcset Url images.
 * @returns {string} Selected image.
 */
const chooseImage = (srcset) => {
  const { big } = srcset
  const image = require(`../../assets/images${big}`)
  return image
}

/**
 * Component Hero.
 * @returns {void} .
 */
const Hero = ({
  small,
  image,
  srcset,
  children
}) => {
  const refImage = useRef()

  /**
   * Load Src Image.
   * @returns {void} .
   */
  const loadSrcImage = () => {
    if (srcset) {
      const imageOfSet = chooseImage(srcset)
      refImage.current.src = imageOfSet
    } else if (image) {
      refImage.current.src = image
    }
  }

  useEffect(() => {
    loadSrcImage()
    window.addEventListener('resize', loadSrcImage)
    return () => window.removeEventListener('resize', loadSrcImage)
  })

  const smallStyle = small ? styles.Small : null

  const imageComponent = (image || srcset) && (
    <div className={styles.HeroBox}>
      <img
        alt="heroBox"
        ref={refImage}
      />
    </div>
  )

  return (
    <div className={styles.Hero}>
      <div className={[styles.HeroImage, smallStyle].join(' ')}>
        {imageComponent}
      </div>
      <div className={[styles.HeroInfo,
        appStyles.CenterContainer,
        smallStyle].join(' ')}
      >
        {children}
      </div>
    </div>
  )
}

export default Hero
