import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './GenericButton.module.css'

/**
 * Generic Button.
 * @returns {void} .
 */
const GenericButton = ({
  inverted,
  link,
  event,
  label,
  to
}) => {
  let button = ''
  const style = inverted ? styles.Inverted : styles.Button
  if (link) {
    button = (
      <a
        href={link}
        className={style}
        onClick={event}
      >
        {label}
      </a>
    )
  } else if (to) {
    button = (
      <NavLink
        to={to}
        className={style}
        onClick={event}
      >
        {label}
      </NavLink>
    )
  } else if (event) {
    button = (
      <div
        className={style}
        onClick={event}
        role="button"
        tabIndex="0"
      >
        {label}
      </div>
    )
  }
  return button
}

export default GenericButton
