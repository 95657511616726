
const messages = {
  title: '¡{name}, gracias por elegir retenedores moons!',
  description: 'Te mandamos unos retenedores nuevos cada tres meses por sólo ',
  oldPrice: '$800.00',
  prices: '$500',
  frequency: ' /trimestrales',
  buttonText: 'SUSCRIBIRME AHORA',
  buttonCancel: 'Cancelar suscripción',
  exitCancel: 'Tu suscripción ha sido cancelada',
}

export default messages
