import React from 'react'
import { withRouter } from 'react-router-dom'
import { capitalize } from '../../Utils/utils'
import appStyles from '../../App.module.css'
import styles from './Subscription.module.css'
import messages from './Subscription.messages'
import Shell from '../Shell/Shell'
import Hero from '../Hero/Hero'

/**
 * Image src function.
 * @returns {void} .
 */
const ImageSrc = () => {
  let heroSmall = '/jpg/hero-small-subscribed.jpg'
  let heroBig = '/jpg/hero-big-subscribed.jpg'

  if (window.webpSupported) {
    heroSmall = '/webp/hero-small-subscribed.webp'
    heroBig = '/webp/hero-big-subscribed.webp'
  }
  return { small: heroSmall, big: heroBig }
}

/**
 * Home component.
 * @returns {void} .
 */
const SuccessSubscription = ({ match }) => {
  const { name } = match.params
  const title = messages.title.replace('{name}', capitalize(name))
  const heroSrc = ImageSrc()

  return (
    <>
      <Shell>
        <Hero srcset={heroSrc}>
          <div className={appStyles.CompleteDesc}>
            <div className={[appStyles.HeroTitle, appStyles.Small].join(' ')}>
              {title}
            </div>
            <div className={[appStyles.HeroDesc, styles.Desc].join(' ')}>
              {messages.description}
            </div>
          </div>
        </Hero>
        <div className={[appStyles.MarginContainer]} />
      </Shell>
    </>
  )
}

export default withRouter(SuccessSubscription)
