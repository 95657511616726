/* eslint-disable require-await */
import PatientsService from './PatientsService'
import { goToStripe } from './Stripe'

const Api = new PatientsService()
const waitingTime = 1000
const attempts = 3
const increment = 1

/**
 * Retry redirect.
 * @param {string} setLoader .
 * @param {string} setError .
 * @param {string} name .
 * @param {string} dealId .
 * @param {string} retry .
 * @returns {void} .
 */
const retryRedirect = async (
  setLoader,
  setError,
  name,
  dealId,
  retry = increment
) => new Promise((resolve, reject) => {
  const time = setTimeout(async () => {
    const patient = await Api.getPatientByDealId(dealId)
    if (patient && patient.get('CustomerId')) {
      goToStripe(name, patient.get('CustomerId'))
    } else if (retry < attempts) {
      // eslint-disable-next-line no-param-reassign
      retry += increment
      clearTimeout(time)
      const res = await retryRedirect(setLoader, setError, name, dealId, retry)
      resolve(res)
    } else {
      setLoader(false)
      const error = 'No se pudo registrar correctamente, por favor intente de nuevo'
      setError(error)
      resolve(false)
    }
  }, waitingTime)
})


/**
 * Sent to CRM.
 * @param {string} name .
 * @param {string} phone .
 * @param {string} email .
 * @param {function} setLoader .
 * @param {function} setError .
 * @returns {void} .
 */
const sentToCRM = async (name, phone, email, setLoader, setError) => {
  const deal = {
    phone,
    email,
    deal: {
      Patient_Name: name,
      Deal_Origin: 'retainers'
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(deal)
  }

  const BlackboxUrl = process.env.REACT_APP_BLACKBOX_URL
  const response = await fetch(BlackboxUrl, requestOptions)
  const data = await response.json()
  if (response.ok) {
    if (data && data.details && data.details.id) {
      const dealId = data.details.id
      await retryRedirect(setLoader, setError, name, dealId)
    } else {
      setLoader(false)
      const error = data && data.message ? data.message : 'Ups ocurrió un error'
      setError(error)
    }
  } else {
    setLoader(false)
    const error = data && data.message ? data.message : 'Ups ocurrió un error'
    setError(error)
  }
}

export default sentToCRM
