import React, { createRef } from 'react'
import style from './mobile.module.css'
import Description from '../description/description'
import { loadedImg, dynamicRef } from '../../../Utils/utils'


/**
 * Mobile carrousel component.
 * @returns {void} .
 */
class Mobile extends React.Component {
  /**
   * Constructor Mobile carrousel.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.curLeft = 0
    this.moveX = 0
    this.startX = 0
    this.curSlide = 0
    this.loadedCnt = 0
    this.slideW = 0
    this.totalSlides = props.info.length
    this.refSlider = createRef()
    this.refSliderContainer = createRef()
    this.refAllSlide = dynamicRef(this.totalSlides)
    this.refDots = createRef()

    this.def = {
      transition: {
        speed: 300,
        easing: '',
        grow: 10
      },
      swipe: true,
      autoHeight: false,
      afterChangeSlide: () => { }
    }

    this.state = {
      footerPosition: 0,
      isChanging: false,
    }
  }

  /**
   * function call after unmount.
   * @returns {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.updateSliderDimension)
    this.init()
    this.updateSliderDimension()
  }

  /**
   * function call after update.
   * @param {string} nextProps .
   * @param {string} nextState .
   * @returns {void}
   */
  componentDidUpdate(nextProps, nextState) {
    const { isChanging } = this.state
    if (isChanging !== nextState.isChanging && isChanging) {
      this.changeDimentionImage()
    }
  }

  /**
   * function call before unmount.
   * @returns {void}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSliderDimension)
  }

  /**
   * function to go next slider.
   * @param {boolean} changing update slider.
   * @param {int} pos next slider position.
   * @returns {void}
   */
    gotoSlide = (changing, pos) => {
      const milisecond = 1000
      const mov = 2
      if (changing) {
        this.curSlide = pos
      }
      this.refSliderContainer.current.style.transition = `left ${this.def.transition.speed / milisecond}s ${this.def.transition.easing}`
      if (this.curSlide === this.initialMove) {
        this.refSliderContainer.current.style.left = '0px'
      } else {
        this.refSliderContainer.current.style.left = `${-this.curSlide * (this.slideW - this.def.transition.grow / mov)}px`
      }

      setTimeout(() => {
        this.refSliderContainer.current.style.transition = ''
      }, this.def.transition.speed)

      this.setDot()
      this.setState({
        isChanging: true
      })
    }

    /**
     * change Dimention Image.
     * @param {boolean} clear clear transform style .
     * @returns {void} .
     */
    changeDimentionImage = (clear) => {
      this.refAllSlide.forEach((child, index) => {
        if (clear) {
          child.current.style.transform = ''
        } else if (this.curSlide === index) {
          child.current.style.transform = 'scale(1)'
          if (this.state.isChanging) this.setState({ isChanging: false })
        } else {
          child.current.style.transform = 'scale(0.9)'
          if (this.state.isChanging) this.setState({ isChanging: false })
        }
      })
    }

    setDot = () => {
      const { children } = this.refDots.current
      for (let index = this.initialMove; index < children.length; index++) {
        if (index === this.curSlide) {
          children[index].classList.add(style.SliderActive)
          children[index].classList.remove(style.SliderBotton)
        } else {
          children[index].classList.add(style.SliderBotton)
          children[index].classList.remove(style.SliderActive)
        }
      }
      this.setState({
        footerPosition: this.curSlide
      })
    }

    /**
     * Event start touching.
     * @param {object} event .
     * @returns {void} .
     */
    startMove = (event) => {
      this.getCurrentLeft()
      const touch = event.targetTouches[0] || event.changedTouches[0]
      this.startX = touch.pageX
      this.increment = 0
    }

    /**
     * Event touching.
     * @param {object} event .
     * @returns {void} .
     */
    Moving = (event) => {
      const touch = event.targetTouches[0] || event.changedTouches[0]
      this.moveX = touch.pageX

      this.refSliderContainer.current.style.left = `${this.curLeft + this.moveX - this.startX}px`
    }

    endMove = () => {
      const cantStop = 40
      this.getCurrentLeft()

      if (Math.abs(this.moveX - this.startX) === this.initialMove) return

      const stayAtCur = Math.abs(this.moveX - this.startX) < cantStop
        || this.moveX === this.initialMove
      const dir = this.startX < this.moveX ? 'left' : 'right'

      if (!stayAtCur) {
        dir === 'left' ? this.curSlide -= 1 : this.curSlide += 1
        if (this.curSlide < this.initialMove) {
          this.curSlide = 0
        } else if (this.curSlide === this.totalSlides) {
          this.curSlide -= 1
        }
      }

      this.gotoSlide(false)
      this.restValues()
    }

    restValues = () => {
      this.startX = 0
      this.moveX = 0
    }

    getCurrentLeft = () => {
      const { left } = this.refSliderContainer.current.style
      if (left) this.curLeft = parseInt(left, 10)
    }

    updateSliderDimension = () => {
      const total = 100
      const porciento = 18.5
      const mov = 10
      if (this.refSlider && this.refSlider.current && this.refSlider.current.style) {
        this.refSlider.current.style.left = `${-this.slideW * this.curSlide}px`
      }
      const out = porciento * window.innerWidth / total
      this.refSliderContainer.current.style.width = `${(window.innerWidth - out) * this.totalSlides}px`

      this.changeDimentionImage(true)
      this.getSlideW()
      this.changeDimentionImage()

      if (this.slideW) {
        this.refSliderContainer.current.style.minHeight = `${this.slideW + mov}px`
      }
    }

    getSlideW = () => {
      const allSlider = this.refAllSlide
      if (allSlider && allSlider.length > this.initialMove
          && allSlider[0] && allSlider[0].current) {
        const node = allSlider[this.curSlide].current
        this.slideW = parseInt(node.offsetWidth, 10)
      } else {
        this.slideW = 0
      }
    }

    init = () => {
      this.initialMove = 0
      const allSlide = this.refAllSlide

      for (const item of allSlide) {
        loadedImg(item.current, this.updateSliderDimension, this.totalSlides)
      }
      this.refSliderContainer.current.style.left = '0px'
      this.setDot()
      this.getSlideW()
    }

    /**
     * Render Component
     * @returns {jsx} .
     */
    render() {
      const { info } = this.props
      const { footerPosition } = this.state
      return (
        <div className={style.CarouselMovil}>
          <div
            ref={this.refSliderContainer}
            onTouchStart={(event) => this.startMove(event)}
            onTouchMove={(event) => this.Moving(event)}
            onTouchEnd={(event) => this.endMove(event)}
            className={style.SlideshowContainer}
          >
            <div
              ref={this.refSlider}
              className={style.SliderMove}
            >
              {info.map((item, index) => {
                const image = window.webpSupported ? item.webpBig : item.jpgBig
                return (
                  <div
                    key={item.id}
                    ref={this.refAllSlide[index]}
                    className={style.StepContainerImages}
                  >
                    <img
                      alt="step-one"
                      data-src={image}
                      className={style.ImageSlider}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div
            ref={this.refDots}
            className={style.SliderBootOut}
          >
            {info.map((item, index) => (
              <button
                type="button"
                key={item.id}
                onClick={() => { this.gotoSlide(true, index) }}
              />
            ))}
          </div>

          {info.map((item, index) => {
            if (footerPosition === index) {
              return (
                <Description
                  key={item.id}
                  item={item}
                />
              )
            }
            return ''
          })}
        </div>
      )
    }
}
export default Mobile
