
const messages = {
  title: '¡{name}, muchas gracias!',
  description: 'Tu subscripción a retenedores moons ha sido aprobada, en breve nos pondremos en contacto contigo para enviarte tu kit de impresión de sonrisa y poder entregarte tu primer par de retenedores.',
  titleError: '¡Upss!',
  descriptionError: 'Parece que hubo un error y tu pago no pudo ser procesado, ',
  tryAgain: 'intenta de nuevo.'
}

export default messages
