import React, { useRef } from 'react'
import { withRouter } from 'react-router-dom'
import appStyles from '../../App.module.css'
import messages from './Home.messages'
import Shell from '../Shell/Shell'
import Hero from '../Hero/Hero'
import GenericButton from '../GenericButton/GenericButton'
import StepsCarousel from '../StepsCarrousel/index'
import steps from '../../Utils/steps.json'
import Subscribe from '../SubscribeForm/Subscribe'

/**
 * Image src function.
 * @returns {void} .
 */
const ImageSrc = () => {
  let heroSmall = '/jpg/hero-small.jpg'
  let heroBig = '/jpg/hero-big.jpg'

  if (window.webpSupported) {
    heroSmall = '/webp/hero-small.webp'
    heroBig = '/webp/hero-big.webp'
  }
  return { small: heroSmall, big: heroBig }
}

/**
 * Home component.
 * @returns {void} .
 */
const Home = () => {
  const advice = (
    <div className={appStyles.HeroAdvice}>
      {messages.advice}
    </div>
  )

  const refHome = useRef()
  const heroSrc = ImageSrc()

  /**
   * Go to form subscribe.
   * @returns {void} .
   */
  const goToSubscribe = () => {
    let yPos = window.scrollY
    const intervalScroll = 5
    const cont = 10
    const timeScrolling = setInterval(() => {
      yPos += cont
      window.scrollTo({ top: yPos })
      if (yPos >= refHome.current.scrollHeight) {
        clearInterval(timeScrolling)
      }
    }, intervalScroll)
  }

  return (
    <div ref={refHome}>
      <Shell>
        <Hero srcset={heroSrc}>
          <div className={appStyles.CompleteDesc}>
            <div className={[appStyles.HeroTitle, appStyles.Small].join(' ')}>
              {messages.title}
            </div>
            {advice}
            <div className={appStyles.HeroDesc}>
              {messages.description}
              <strike>{messages.oldPrice}</strike>
            </div>
            <div className={appStyles.heroPrices}>
              <div className={appStyles.HeroNumber}>{messages.prices}</div>
              {messages.frequency}
            </div>
            <div className={appStyles.ButtonContainer}>
              <GenericButton
                label={messages.buttonText}
                event={goToSubscribe}
              />
            </div>
          </div>
        </Hero>
        <div className={[appStyles.MarginContainer]}>
          <StepsCarousel
            info={steps}
            newUser
          />
        </div>
        <Subscribe />
      </Shell>
    </div>
  )
}

export default withRouter(Home)
