/**
 * Go to stripe .
 * @param {string} name .
 * @param {string} id .
 * @returns {void} .
 */
const goToStripe = (name, id) => {
  const currentId = id ? `/${id}` : ''
  const currentName = name ? encodeURI(name) : ''
  const stripeId = process.env.REACT_APP_STRIPE_ID
  const stripe = window.Stripe(`${stripeId}`)
  stripe.redirectToCheckout({
    items: [{ plan: process.env.REACT_APP_STRIPE_PLAN, quantity: 1 }],
    clientReferenceId: id,

    /*
     * Do not rely on the redirect to the successUrl for fulfilling
     * purchases, customers may not always reach the success_url after
     * a successful payment.
     * Instead use one of the strategies described in
     * https://stripe.com/docs/payments/checkout/fulfillment
     */
    successUrl: `https://retenedores.mymoons.mx/success/${currentName}`,
    cancelUrl: `https://retenedores.mymoons.mx/failure${currentId}`,
  })
    .then((result) => {
      if (result.error) {
        /*
         * If `redirectToCheckout` fails due to a browser or network
         * error, display the localized error message to your customer.
         */
        const displayError = document.getElementById('error-message')
        displayError.textContent = result.error.message
      }
    })
}

/**
 * Cancel stripe .
 * @param {string} id .
 * @param {string} callback .
 * @returns {void} .
 */
const cancelStripe = async (id, callback) => {
  const CancelStripeUrl = process.env.REACT_APP_CANCEL_STRIPE_URL
  const BasicAuthUsername = process.env.REACT_APP_BASIC_AUTH_USERNAME
  const BasicAuthPassword = process.env.REACT_APP_BASIC_AUTH_PASSWORD

  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${btoa(auth)}`

  const subscription = {
    RetainerSubscriptionId: id
  }

  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(subscription),
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
  }

  await fetch(CancelStripeUrl, requestOptions)
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json()
        if (data && data.id) {
          return callback(data.id)
        }
      } else {
        const error = true
        return callback(null, error)
      }
    }).catch((err) => {
      const error = !!err
      return callback(null, error)
    })
}

export {
  goToStripe,
  cancelStripe
}
