import React, { Suspense } from 'react'
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect
} from 'react-router-dom'

import Home from './Components/Home/Home'
import HomeUser from './Components/HomeUser/HomeUser'
import SuccessSusbscription from './Components/CallbackSuscribe/SuccessSubscription'
import ErrorSubscription from './Components/CallbackSuscribe/ErrorSubscription'

/**
 * App component.
 * @returns {BrowserRouter} All routes.
 */
const App = () => (
  <BrowserRouter>
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path="/"
          component={Home}
        />
        <Route
          exact
          path="/user/:id"
          component={HomeUser}
        />
        <Route
          exact
          path="/success/:name"
          component={SuccessSusbscription}
        />
        <Route
          exact
          path={['/failure', '/failure/:id']}
          component={ErrorSubscription}
        />
        <Route
          path="/"
          render={() => <Redirect to="/" />}
        />
      </Switch>
    </Suspense>
  </BrowserRouter>
)

export default App
