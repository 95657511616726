import React, { useState } from 'react'
import styles from './Subscribe.module.css'
import appStyles from '../../App.module.css'
import Form from '../Form/Form'
import retainer from '../../assets/images/jpg/retainer-big.jpg'
import messages from './Subscribe.messages'
import sentToCRM from '../../Apis/BlackBox'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'

/**
 * Intro component.
 * @returns {void} .
 */
const Intro = () => (
  <div className={styles.Intro}>
    <div className={styles.Title}>
      {messages.title}
    </div>
    <div className={styles.Description}>
      {messages.description}
    </div>
  </div>
)

/**
 * Image component.
 * @returns {void} .
 */
const Image = () => (
  <div className={styles.ImageRetainerContainer}>
    <img
      alt="retainer"
      src={retainer}
      className={styles.ImageRetainer}
    />
  </div>
)

/**
 * FormContainer component.
 * @param {function} setLoader .
 * @returns {void} .
 */
const FormContainer = ({ setLoader, setError }) => (
  <div className={styles.FormContainer}>
    <Form
      button={messages.buttonText}
      submit={({ name, phone, email }) => {
        setLoader(true)
        sentToCRM(name, phone, email, setLoader, setError)
      }
      }
    />
  </div>
)

/**
 * Suscribe component.
 * @returns {void} .
 */
const Subscribe = () => {
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)

  return (
    <>
      {loader ? (<LoaderFullScreen message={messages.loaderText} />)
        : (
          <div className={styles.FooterSuscribe}>
            <div className={styles.Mobile}>
              <Intro />
              <Image />
              <FormContainer
                setLoader={setLoader}
                setError={setError}
              />
              {error && (
                <div className={styles.Error}>{error}</div>
              )}
            </div>
            <div className={[styles.Desktop, appStyles.ContentWrapper].join(' ')}>
              <Image />
              <div className={styles.Rigth}>
                <Intro />
                <FormContainer
                  setLoader={setLoader}
                  setError={setError}
                />
                {error && (
                  <div className={styles.Error}>{error}</div>)}
              </div>
            </div>
          </div>
        )}
    </>
  )
}


export default Subscribe
