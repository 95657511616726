import React from 'react'
import styles from './Shell.module.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

/**
 * Container all app.
 * @returns {void} .
 */
const Shell = ({ children, headerDisabled, footerDisabled }) => {
  const header = headerDisabled === true ? null : <Header />
  const footer = footerDisabled === true ? null : <Footer />

  return (
    <>
      {header}
      <div className={styles.Container}>{children}</div>
      {footer}
    </>
  )
}

export default Shell
