import { createRef } from 'react'

// eslint-disable-next-line no-magic-numbers
let loadedCnt = 0

/**
 * Function to load image.
 * @param {tag} el element that contain img
 * @param {function} callback function to update sliders
 * @param {int} totalSlides total slider.
 * @returns {void} .
 */
const loadedImg = (el, callback, totalSlides) => {
  let loaded = false
  const increment = 1

  /**
   * Function call when load image.
   * @returns {void} .
   */
  const loadHandler = () => {
    if (loaded) return
    loaded = true
    loadedCnt += increment
    if (loadedCnt >= totalSlides) {
      // eslint-disable-next-line callback-return
      callback()
    }
  }
  const img = el.firstElementChild
  if (img) {
    img.onload = loadHandler
    // eslint-disable-next-line global-require
    img.src = require(`../assets/images/${img.dataset.src}`)
    img.style.display = 'block'
    if (img.complete) {
      loadHandler()
    }
  } else {
    return callback()
  }
  return ''
}

/**
 * Create array of ref.
 * @param {int} total number of array elements .
 * @returns {void} .
 */
const dynamicRef = (total) => {
  const initial = 0
  const refs = []
  for (let index = initial; index < total; index++) {
    refs[index] = createRef()
  }
  return refs
}

/**
 * Get style item by property.
 * @param {int} node .
 * @param {int} property  .
 * @returns {void} .
 */
const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

/**
 * Capitalize word.
 * @param {string} word .
 * @returns {void} .
 */
const capitalize = (word) => {
  const initial = 0
  const next = 1
  const stringCap = word.charAt(initial).toUpperCase() + word.slice(next)
  return stringCap
}

/**
 * Is param a object ?.
 * @param {string} obj .
 * @returns {void} .
 */
const isObject = (obj) => typeof obj === 'object' && obj !== null

export {
  loadedImg,
  dynamicRef,
  getStyleItemByProperty,
  capitalize,
  isObject
}
