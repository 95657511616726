import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import logo from '../../assets/images/svg/logo.svg'


/**
 * Header component.
 * @returns {void} .
 */
const Header = () => (
  <header
    id="header"
    className={styles.MainContainer}
  >
    <div className={styles.Logo}>
      <Link
        to="/"
        className={styles.LinkHome}
      >
        <img
          alt="logo"
          src={logo}
        />
      </Link>
    </div>
  </header>
)


export default Header
