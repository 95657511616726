import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import appStyles from '../../App.module.css'
import styles from './Subscription.module.css'
import messages from './Subscription.messages'
import Shell from '../Shell/Shell'
import Hero from '../Hero/Hero'

/**
 * Image src function.
 * @returns {void} .
 */
const ImageSrc = () => {
  let heroSmall = '/jpg/hero-small-subscribed.jpg'
  let heroBig = '/jpg/hero-big-subscribed.jpg'

  if (window.webpSupported) {
    heroSmall = '/webp/hero-small-subscribed.webp'
    heroBig = '/webp/hero-big-subscribed.webp'
  }
  return { small: heroSmall, big: heroBig }
}

/**
 * Home component.
 * @returns {void} .
 */
const ErrorSubscription = ({ match }) => {
  const heroSrc = ImageSrc()
  const { id } = match.params
  const goToHome = id ? `/user/${id}` : '/'

  return (
    <Shell>
      <Hero srcset={heroSrc}>
        <div className={appStyles.CompleteDesc}>
          <div className={[appStyles.HeroTitle, appStyles.Small].join(' ')}>
            {messages.titleError}
          </div>
          <div className={[appStyles.HeroDesc, styles.Desc].join(' ')}>
            {messages.descriptionError}
            <NavLink
              to={goToHome}
              className={styles.Link}
            >
              {messages.tryAgain}
            </NavLink>
          </div>
        </div>
      </Hero>
      <div className={[appStyles.MarginContainer]} />
    </Shell>
  )
}

export default withRouter(ErrorSubscription)
