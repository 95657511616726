import React from 'react'
import styles from './LinkCancel.module.css'
import arrow from '../../assets/images/svg/right-arrow.svg'
import messages from './LinkCancel.messages'

/**
 * Link to cancel suscription.
 * @returns {void} .
 */
const LinkCancel = ({
  event,
  label,
  error
}) => {
  let button = ''

  button = (
    <div
      role="button"
      tabIndex="0"
      className={styles.Button}
      onClick={event}
    >
      <span className={styles.Span}>{label}</span>
      <img
        className={styles.NextArrow}
        alt={label}
        height="11"
        width="32"
        src={arrow}
      />
      {error && (<div className={styles.Error}>{messages.error}</div>)}
    </div>
  )

  return button
}

export default LinkCancel
