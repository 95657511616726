import React from 'react'
import styles from './Footer.module.css'
import messages from './Footer.messages'
import imgFacebook from '../../assets/images/svg/facebook.svg'
import imgInstagram from '../../assets/images/svg/instagram.svg'
import imgLinkedIn from '../../assets/images/svg/linked-in.svg'
import imgTwitter from '../../assets/images/svg/twitter.svg'
import imgYoutube from '../../assets/images/svg/youtube.svg'

const social = [
  {
    id: 0,
    link: 'https://www.linkedin.com/company/alineadores/',
    label: 'LinkedIn Moons',
    image: imgLinkedIn
  },
  {
    id: 1,
    link: 'https://twitter.com/welovemoons',
    label: 'Twitter Moons',
    image: imgTwitter
  },
  {
    id: 2,
    link: 'https://youtube.com/c/welovemoons',
    label: 'Youtube Moons',
    image: imgYoutube
  },
  {
    id: 3,
    link: 'https://www.instagram.com/welovemoons.co/',
    label: 'Instagram Moons',
    image: imgInstagram
  },
  {
    id: 4,
    link: 'https://www.facebook.com/welovemoons/',
    label: 'Facebook Moons',
    image: imgFacebook
  }
]

/**
 * Footer component.
 * @returns {void} .
 */
const Footer = () => (
  <div className={styles.Container}>
    <div className={styles.LinksContainer}>
      <a
        className={styles.Link}
        href="https://mymoons.mx/aviso-de-privacidad"
      >
        {messages.privacy}
      </a>
      <a
        className={styles.Link}
        href="https://mymoons.mx/terminos-y-condiciones"
      >
        {messages.service}
      </a>
    </div>
    <div className={styles.SocialNetworksContainer}>
      {social.map((item) => (
        <a
          key={item.id}
          href={item.link}
        >
          <img
            className={styles.SocialNetwork}
            src={item.image}
            alt={item.label}
          />
        </a>
      ))}
    </div>
    <div className={styles.Text}>
      {messages.copyrigth}
    </div>
  </div>
)

export default Footer
