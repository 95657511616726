
const messages = {
  title: 'Mantén tu sonrisa \n mientras duermes.',
  advice: 'No dejes que los resultados de usar alineadores invisibles o brackets se desperdicien.',
  description: 'Te mandamos unos retenedores nuevos cada tres meses por sólo ',
  oldPrice: '$800.00',
  prices: '$500',
  frequency: ' /trimestrales',
  buttonText: 'QUIERO SUSCRIBIRME',
}

export default messages
