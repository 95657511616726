const messages = {
  name: 'Nombre y Apellido',
  nameError: 'Ingresa tu nombre y apellido',
  phone: 'Whatsapp',
  phoneError: 'Ingresa un celular válido',
  email: 'Correo electrónico',
  emailError: 'Ingresa un correo válido'
}

export default messages
