import Parse from 'parse'
import { Component } from 'react'
import { isObject } from '../Utils/utils'

/**
 * Get patient.
 * @returns {void} .
 */
class PatientsService extends Component {
  /**
   * Constructor.
   * @returns {void} .
   */
  constructor() {
    super()
    Parse.serverURL = process.env.REACT_APP_SERVER_URL
    Parse.initialize(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_JAVASCRIPT_KEY)
  }

  /**
   * Get patient by deal_id.
   * @param {string} dealId .
   * @returns {void} .
   */
    getPatientByDealId = async dealId => {
      const Patients = Parse.Object.extend('Patient')
      const query = new Parse.Query(Patients)
      query.equalTo('Deal_Id', dealId)
      const patient = await query.first()
      return patient
    }

    /**
     * Get patient by id.
     * @param {string} id CustomerId .
     * @returns {void} .
     */
    getPatientById = async (id) => {
      const Patients = Parse.Object.extend('Patient')
      const query = new Parse.Query(Patients)

      query.equalTo('CustomerId', id)
      const response = await query.first().then(
        results => {
          let data = null
          if (results && isObject(results)) {
            data = JSON.stringify(results)
          }
          return data
        },
        error => {
          throw error
        }
      )
      return JSON.parse(response)
    }
}

export default PatientsService
