import React from 'react'
import style from './description.module.css'

/**
 * Description slider component.
 * @returns {void} .
 */
const Description = ({ item }) => (
  <div className={style.ContainerText}>
    <div className={style.CarrouselTitle}>{item.label}</div>
    <div className={style.CarrouselDescription}>{item.description}</div>
  </div>
)


export default Description
