import React, { Component, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import PatientsService from '../../Apis/PatientsService'
import { capitalize, isObject } from '../../Utils/utils'
import { goToStripe, cancelStripe } from '../../Apis/Stripe'
import appStyles from '../../App.module.css'
import styles from './HomeUser.module.css'
import messages from './HomeUser.messages'
import Shell from '../Shell/Shell'
import Hero from '../Hero/Hero'
import GenericButton from '../GenericButton/GenericButton'
import StepsCarousel from '../StepsCarrousel/index'
import steps from '../../Utils/steps.json'
import LinkCancel from '../LinkCancel/LinkCancel'

/**
 * Image src function.
 * @returns {void} .
 */
const ImageSrc = () => {
  let heroSmall = '/jpg/hero-small-subscribed.jpg'
  let heroBig = '/jpg/hero-big-subscribed.jpg'

  if (window.webpSupported) {
    heroSmall = '/webp/hero-small-subscribed.webp'
    heroBig = '/webp/hero-big-subscribed.webp'
  }
  return { small: heroSmall, big: heroBig }
}

/**
 * Home component.
 * @returns {void} .
 */
class HomeUser extends Component {
  /**
   * Home component.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
      patient: {},
      titleHero: '',
      error: false,
      msg: false
    }
    this.id = props.match.params.id
    this.heroSrc = ImageSrc()
    this.refHomeUser = createRef(null)
  }

  /**
   * Home component.
   * @returns {void} .
   */
  componentDidMount() {
    const position = 0
    window.scrollTo(position, position)
    this.getPatient(this.id)
  }

  setTitle = () => {
    const { patient } = this.state
    const title = patient.name
      ? messages.title.replace('{name}', capitalize(patient.name))
      : messages.title
    this.setState({ titleHero: title })
  }

  /**
   * Try set suscription.
   * @param {string} subsId .
   * @param {string} error .
   * @returns {void} .
   */
  trySetSuscription = (subsId, error) => {
    const { patient } = this.state
    if (patient && subsId) {
      this.setState({
        patient: {
          ...patient,
          subscribed: !subsId,
        },
        msg: true
      })
    } else if (error) {
      this.setState({ error })
    }
  }


  /**
   * Get patient controller.
   * @param {string} id .
   * @returns {void} .
   */
  getPatient = async (id) => {
    let data = ''
    const Api = new PatientsService()
    try {
      data = await Api.getPatientById(id)
      if (data && isObject(data)) {
        this.setState({
          patient: {
            name: data.Patient_Name,
            subscribed: data.Retainer_Subscription,
            retainerSubscriptionId: data.Retainer_Subscription_Id
          }
        })
        this.setTitle()
      } else {
        window.location.href = '/'
      }
    } catch (error) {
      window.location.href = '/'
    }
  }

  /**
   * Home component.
   * @returns {void} .
   */
  render() {
    const {
      patient,
      titleHero,
      error,
      msg
    } = this.state
    return (
      <div ref={this.refHomeUser}>
        <Shell>
          <Hero srcset={this.heroSrc}>
            <div className={appStyles.CompleteDesc}>
              <div className={[appStyles.HeroTitle, appStyles.Small].join(' ')}>
                {titleHero}
              </div>
              <div className={appStyles.HeroAdvice}>
                {messages.advice}
              </div>
              <div className={appStyles.HeroDesc}>
                {messages.description}
                <strike>{messages.oldPrice}</strike>
              </div>
              <div className={appStyles.heroPrices}>
                <div className={appStyles.HeroNumber}>{messages.prices}</div>
                {messages.frequency}
              </div>
              <div className={appStyles.ButtonContainer}>
                {patient && !patient.subscribed ? (
                  <GenericButton
                    event={() => goToStripe(patient.name, this.id)}
                    label={messages.buttonText}
                  />
                )
                  : patient && patient.retainerSubscriptionId && (
                    <LinkCancel
                      event={() => {
                        cancelStripe(patient.retainerSubscriptionId, this.trySetSuscription)
                      }}
                      label={messages.buttonCancel}
                      error={error}
                    />
                  )}
              </div>
              {msg && (<div className={styles.ExitCancel}>{messages.exitCancel}</div>)}
            </div>
          </Hero>
          <div className={[appStyles.MarginContainer]}>
            <StepsCarousel info={steps} />
          </div>
        </Shell>
      </div>
    )
  }
}

export default withRouter(HomeUser)
